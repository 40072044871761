export const pluralize = (number, one, few, many) => {
  const last = number % 10;
  const lastTwo = number % 100;

  if (lastTwo >= 11 && lastTwo <= 14) {
    return many;
  }
  if (last === 1) {
    return one;
  }
  if (last >= 2 && last <= 4) {
    return few;
  }
  return many;
};

export const removeSpaces = (arr) => arr?.replace(/\s/g, "");

export const getCurrentState = (state) => {
  try {
    return JSON.parse(JSON.stringify(state));
  } catch (e) {
    return null;
  }
};

export const getRandomArbitrary = (min = 400, max = 600) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const parseBodyToTagsId = (tags, withoutTags = false) => {
  return tags.map((tag) => `${withoutTags ? "" : "tags="}${tag.id}`).join("&");
};

export const getAverageRating = (ratings) => {
  if (ratings.length === 0) {
    return null;
  }
  const totalScore = ratings.reduce((sum, rating) => sum + rating.score, 0);
  const averageScore = totalScore / ratings.length;
  let roundedScore;
  if (averageScore >= 5) {
    roundedScore = Math.ceil(averageScore * 10) / 10;
  } else {
    roundedScore = Math.floor(averageScore * 10) / 10;
  }
  return roundedScore === 10 ? roundedScore : roundedScore.toFixed(1);
};
