import { createSlice } from "@reduxjs/toolkit";

const campusDiscountSlice = createSlice({
  name: "campusDiscount",
  initialState: {
    data: null,
    loading: false,
    error: "",
  },
  reducers: {
    fetchCampusDiscountSuccess(state, action) {
      state.data = action.payload;
    },
    fetchCampusDiscountLoading(state, action) {
      state.loading = action.payload;
    },
    fetchCampusDiscountError(state, action) {
      state.error = action.payload;
    },
  },
});

export const campusDiscountReducer = campusDiscountSlice.reducer;
export const {
  fetchCampusDiscountSuccess,
  fetchCampusDiscountLoading,
  fetchCampusDiscountError,
} = campusDiscountSlice.actions;
